


















































































import Vue from 'vue';
import Component from 'vue-class-component';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import Excel from 'exceljs';
import API, { Types } from '../modules/API';
import {Reports, User, Global } from '@/store';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { Prop, Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';
import MessageDialog from './dialogs/MessageDialog.vue';

@Component({
    components: {
        ConfirmDialog,
        MessageDialog
    }
})
export default class SetPoleNumByHardwareid extends Vue {
    @Reports.State('reportsList') devices;
    @Reports.State('devicesCount') devicesCount;
    @Global.State('readonly_user') readonly_user;
    @Global.Mutation('setPageTitle') setPageTitle;

    user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));

    // general functionality
    loading = false;
    META_DEVICE_FIELD = 'pole_number';
    prop_utils = PropUtils;

    // used on mounted
    saved_columns = ['pole_number'];
    showMessage = false;
    message = 'You must choose at least one field to update';

    get names_not_used() { // computing to only show the names for fields that were not selected yet.
        const array_of_relations = this.columns_relations.map((item) => item.nameInDB);
        return this.saved_columns.filter((item) => array_of_relations.indexOf(item) === -1);
    }

    // variables to read excel to Javascript's handling, used after uploading excel file by user
    workbook: any = null;
    worksheets = [];
    sheetNumber = -1;

    // table variables after reading excel
    table = [];
    columns_relations = []; // the <th>s cells of the colum. array of objects.
    hardwareid_column = -1; // the main column is the hardwareid_column. if it's "-1" then it's uninitialized
    showTooltipSetColumn = false;
    
    showDialog = false;
    dialogText = '';
    dialogType = null;
    dialogCancelText = 'Cancel';
    logs = [];
    valid_hardwareid_rows = new Map();
    poles_units = [];
    hardwareid_units = new Map();
    devicesFoundInSheet = 0;

    mounted() {
        this.setPageTitle('Set Pole Number By Hardwareid');
        this.getDevicesToUnitsPoles();
    }

    @Watch('devices')
    @Watch('devicesCount')
    getDevicesToUnitsPoles() {
        if (this.devices.length !== this.devicesCount) return;
        this.devices.forEach((device, index) => {
            const metaDevice = device['meta.device'];
            const hardwareidProp = PropUtils.parseProperty('device.hardwareid', device);      
            const hardware_id = hardwareidProp.id ? hardwareidProp.id.toLowerCase() : '';
            metaDevice && metaDevice[this.META_DEVICE_FIELD]
                ? this.poles_units.push(metaDevice[this.META_DEVICE_FIELD].toString())
                : this.poles_units.push(null);

            if (hardware_id) this.hardwareid_units.set(index, hardware_id);
        });
    }

    async loadExcel(event) {
        // reset state 
        this.loading = true;
        this.logs = [];
        this.worksheets = [];
        this.sheetNumber = -1;
        this.hardwareid_column = -1;
        this.table = [];
        this.columns_relations = [];
        
        const wb = new Excel.Workbook(), 
        reader = new FileReader(),
        that = this;
        reader.readAsArrayBuffer(event.target.files[0]);
        reader.onload = () => {
            const buffer = reader.result;
            if (typeof buffer !== 'string')
            wb.xlsx.load(buffer).then(async (workbook) => {
                workbook.eachSheet((sheet) => this.worksheets.push(sheet.name));
                that.workbook = workbook;
                that.loading = false;
                this.openModal('choose_worksheet');
            });
        };
    }

    styleCell(row_index, column_index: number) {
        if (this.hardwareid_column > -1){
            const valid_rows = [...this.valid_hardwareid_rows.keys()];
            if (column_index === this.hardwareid_column && !valid_rows.includes(row_index)) return 'background-color: red; color: white;';
            else if (column_index === this.hardwareid_column && valid_rows.includes(row_index)) return 'background-color: green; color: white;';
        }
        return '';
    }

    get valid_hardwareid_row_nums(){
        return [...this.valid_hardwareid_rows.keys()];
    }

    async loadSheet(sheetNumber: number) {
        // reset state
        this.devicesFoundInSheet = 0;
        this.table = [];
        this.sheetNumber = sheetNumber;
        this.columns_relations = [];
        this.loading = true;
        this.hardwareid_column = -1;

        const sheet = await this.workbook.worksheets[sheetNumber];
        if (sheet === undefined) alert(this.$t('Error choosing worksheet. Please check the file or decrease its size by separating sheets and try again.'));
        await sheet.getRow(1).eachCell({includeEmpty: true}, (cell: string, cellIndex: number) => this.columns_relations.push({nameInFile: cell, nameInDB: null, key: ('head' + cellIndex.toString())}));
        await sheet.eachRow(async (row, rowIndex: number) => {
            const rowArray = [];
            row.eachCell({ includeEmpty: true }, (cell, cellIndex: number) => rowArray.push({text: cell.toString(), key: ('cell' + cellIndex.toString() + rowIndex.toString())}));
            this.table.push(rowArray);
            this.openModal('choose_hardware_id');
        });
        this.loading = false;
    }

    defineAsPoleColumn(i) {
        if (i > -1) this.openModal('set_hardware_id');
        if (this.hardwareid_column > -1) this.columns_relations[this.hardwareid_column].nameInDB = null; // if the column already choosed and needs to release
        this.hardwareid_column = i;
        if (this.hardwareid_column > -1) this.columns_relations[i].nameInDB = 'device.hardwareid';
        else this.releaseMainColumn();
    }

    async saveInDB() {
        this.loading = true;
        const pole_number_index = this.columns_relations.findIndex((item, i) => item.nameInDB && item.nameInDB === this.META_DEVICE_FIELD);

        if (pole_number_index === -1) return;

        await this.table.forEach(async (row, row_index) => {
            if (![...this.valid_hardwareid_rows.keys()].includes(row_index)){
                this.logs.push(`problem with row number ${ (row_index + 1) }. device.hardwareid ${row[this.hardwareid_column].text.toString()} is not present in any of the units queried for the project.`);
                return;
            }
            const pole_number = row[pole_number_index] && row[pole_number_index].text && row[pole_number_index].text.toString().trim()
                ? row[pole_number_index].text.toString().trim()
                : '';

            if (pole_number){
                const device = this.devices[this.valid_hardwareid_rows.get(row_index)];

                if (device){
                    const metaDevice = cloneDeep(device['meta.device']);
                    metaDevice[this.META_DEVICE_FIELD] = Utils.removeLeadingZeroesAndSpaces(pole_number);
                    const uppercase_field = PropUtils.findMatchedUppercaseField(metaDevice, this.META_DEVICE_FIELD);
                    if (uppercase_field){
                        metaDevice[uppercase_field] = metaDevice[this.META_DEVICE_FIELD];
                    }
                    try {
                        await API.patch(Types.DEVICES, `/${device.id}/properties`, metaDevice, {query: {name: 'meta.device'}});
                        device['meta.device'] = metaDevice;
                    }catch (e) {
                        this.logs.push(`problem with row number ${ (row_index + 1) } with device id ${ device.id }: unknown internal error.`);
                    }
                }
            }
        });
        this.loading = false;
        this.openModal('saved_to_db');
    }

    setFieldsToRow(row, selected_indexes) {
        const formData = {};
        selected_indexes.forEach((index) => {
            const value = row && row[index] && row[index].text ? row[index].text : null;
            formData[(this.columns_relations[index].nameInDB)] = value;
        });
        if (this.columns_relations[this.hardwareid_column] && this.columns_relations[this.hardwareid_column].nameInDB && row[this.hardwareid_column] && row[this.hardwareid_column].text) formData[this.columns_relations[this.hardwareid_column].nameInDB] = row[this.hardwareid_column].text.toString();
        return formData;
    }
    
    // Dialog functions 
    openModal(type: string) {
        this.dialogType = type;
        this.showDialog = true;
        if (type === 'saved_to_db') {
            this.dialogText = this.$t('The file has been received successfully');
            this.dialogCancelText = this.$t('Download Log File');
        }
        if (type === 'set_hardware_id') this.dialogText = this.$t('Are you sure that you want to mark this column as Hardware ID? a mistake will cause errors because this is a main indication');
        if (type === 'save_in_db') {
            if (this.names_not_used.length === this.saved_columns.length){
                this.showMessage = true;
                this.showDialog = false;
            }else {
                this.dialogText = this.$t('Are you sure that you want to save the uploaded Excel file to the system?');
            }
        }
        if (type === 'choose_worksheet') this.dialogText = this.$t('Please choose the tab you would like to upload first');
        if (type === 'choose_hardware_id') this.dialogText = this.$t('Please choose the column of Hardware ID to function as the main column');
    }

    cancelModalAction() {
        if (this.dialogType === 'set_hardware_id') this.defineAsPoleColumn(-2);
        if (this.dialogType === 'save_in_db') this.dialogType = null;
        if (this.dialogType === 'saved_to_db') this.downloadLog();
        this.closeModal();
    }

    releaseMainColumn(){
        this.valid_hardwareid_rows = new Map();
        this.devicesFoundInSheet = 0;
    }
    
    closeModal() {
        if (this.dialogType === 'set_hardware_id' && this.hardwareid_column > -1) {
            this.showTooltipSetColumn = true; 
            this.setValidHardwareIdBySheet();
        }
        if (this.dialogType === 'save_in_db') this.saveInDB();
        this.dialogType = null;
        this.showDialog = false;
        this.dialogText = '';
        this.dialogCancelText = 'Cancel';
    }

    setValidHardwareIdBySheet(){
        let hardware_id_values = this.table.map((row, index) => [index, row[this.hardwareid_column].text.toString().toLowerCase().trim()]);
        hardware_id_values = hardware_id_values.filter(([row_num, text]) => text);
        [...this.hardwareid_units.entries()]
            .map(([device_index, hardware_id]) => {
                const row = hardware_id_values.find(([row_num, sheet_hardware_id]) => sheet_hardware_id.includes(hardware_id));
                return row ? [device_index, row[0]] : [device_index, null];
            })
            .filter(([device_index, row_num]) => row_num === 0 || row_num)
            .forEach(([device_index, row_num]) => this.valid_hardwareid_rows.set(row_num, device_index));
                
        this.devicesFoundInSheet = this.valid_hardwareid_rows.size;
    }   

    downloadLog() {
        const date = new Date(), type = 'text/plain', 
        name = `logs_${date.getMinutes().toString()}_${date.getHours().toString()}_${date.getMonth().toString()}_${date.getFullYear().toString()}.txt`,
        text = this.logs.join('  \n'),
        blob = new Blob([text], {type}),
        url = window.URL.createObjectURL(blob),
        link = document.createElement('a');
        link.download = name;
        link.href = url;
        link.click();
        this.logs = [];
    }
}
